import React, { useState, useEffect, useCallback } from 'react';
import { change, untouch, Field, reduxForm } from 'redux-form';
import { OuterFormColLeft, OuterFormColRight } from '../styles/FormStyles';
import { MAIN_COLOR, NO_ACTIVE_BG_COLOR } from '../../../constants/css';
import Row from 'react-bootstrap/Row';
import PropTypes from 'prop-types';
import { required } from 'redux-form-validators';
import SelectWrapper from "../forms/SelectWrapper"
import { Switcher } from '../../Switcher/Switcher';
import styled from 'styled-components'
import { ReactComponent as Info } from '../../../images/icons/INfo.svg';


const Container = styled(Row)`
  margin-bottom: 140px;
`

const StyledRow = styled(Row)`
  margin: 55px 0 35px 0;
  font-weight: 700;
`

const InfoContainer = styled.div`
  display: flex;
  margin: 55px 0 35px 0;
  flex-direction: row;
  padding-top: 4rem
  `

const InfoIconBlock = styled(Row)`
  margin: 0rem 1rem;
  max-width: 1.5rem;
  height: fit-content;

`
const InfoTextBlock = styled.div`
  display: flex;
  `

const FallbackTariffSelector = ({ 
    status,
    fallbackTariff,
    fallbackTariffOptions,
    disabled,
    dispatch,
  }) => {

  const [showSelector, setShowSelector] = useState(false);
  const [selectorOptions, setSelectorOptions] = useState([]);

  const isActivated = Boolean(status === "activated");

  useEffect(() => {
    setSelectorOptions(fallbackTariffOptions)
    if(fallbackTariff) setShowSelector(true);

  }, [fallbackTariffOptions, fallbackTariff]);
  

  const onCheckBoxChange = useCallback((event) => {
    const isChecked = event.target.checked;
    setShowSelector(isChecked);

    if(!isChecked){
      dispatch(change("productDefinitionForm", "fallback_tariff", null));
      dispatch(untouch("productDefinitionForm", "fallback_tariff"));
    } 
  }, [dispatch]);


  const handleSelectorChange = useCallback((value) => {
    if(isActivated) return;
    dispatch(change("productDefinitionForm", "fallback_tariff", value));
  }, [dispatch]);


  const handleDisableClick = () => isActivated || disabled; // if POD status is activated or disabled is true, return true thsu disable click

  const disableSelector = () => !showSelector || isActivated // if showSelector is false or POD status is activated, return true thus disabling the selector

  const handleBackGroundColor = () => isActivated ? NO_ACTIVE_BG_COLOR : showSelector ? MAIN_COLOR : NO_ACTIVE_BG_COLOR;
  

    return (
        <Container>
          <OuterFormColLeft md={6} lg={6}>
            <StyledRow>
              ÜBERGANGSTARIF
            </StyledRow>
            <Field
              switchId={2}
              labelText="Dieser dynamische Tarif soll zusammen mit einem Übergangstarif angeboten werden"
              component={Switcher}
              handleChange={(event)=>onCheckBoxChange(event)}
              isChecked={showSelector}
              disableClick={handleDisableClick()}
              disabled={disabled}
              backgroundActiveColor={handleBackGroundColor()}
              justifyContent="start"
              activeText="ja"
              inActiveText="nein"
              positionText="right"
              className="mt-5"
            />

          {showSelector && ( 
            <Field
              name={"fallback_tariff"} 
              labelText="Übergangstarif"
              component={SelectWrapper}
              optionsList={selectorOptions}
              validate={[required()]}
              disabled={disableSelector()}
              className="mt-5"
              selector={showSelector}
              onChange={(value)=> handleSelectorChange(value)}
              />
          )}
          </OuterFormColLeft>
          <OuterFormColRight md={6} lg={6}>
            <InfoContainer>
              <InfoIconBlock>
                <Info/>
              </InfoIconBlock>
              <InfoTextBlock>
                  Kunden können nur im dynamischen Tarif abgerechnet werden, wenn sie alle Voraussetzungen erfüllen:
                  <br/>
                  - intelligentes Messsystem vorhanden
                  <br/>
                  - Bilanzierungsart: Lastgangbilanzierung (TAF 7)
                  <br/>
                  <br/>

                  Wird ein Übergangstarif für den dynamischen Tarif definiert, erfolgt nach Vertragsabschluss des dynamischen Tarifs die individuelle Prüfung der Voraussetzungen beim Kunden im Rahmen der Netzanmeldebestätigung durch den Netzbetreiber. Sollten die Voraussetzungen zu diesem Zeitpunkt nicht erfüllt sein, erfolgt ein automatisierter Produktwechsel des Kunde zunächst auf den Übergangstarif und später nach Erfüllung der Voraussetzungen ein automatisiert Produktwechsel auf den dynamischen Stromtarif.
              </InfoTextBlock>
            </InfoContainer>
          </OuterFormColRight>
        </Container>
    );
};

const FallbackTariffOptionsType = PropTypes.shape ({
  id:PropTypes.string.isRequired,
  value:PropTypes.string.isRequired,
  label:PropTypes.string.isRequired
})

FallbackTariffSelector.propTypes = {
  fallbackTariffOptions: PropTypes.arrayOf(PropTypes.shape(FallbackTariffOptionsType)).isRequired,
  fallbackTariff: PropTypes.string,
  status: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired
}

export default reduxForm({
      form: "productDefinitionForm",  
      destroyOnUnmount: false,
      forceUnregisterOnUnmount: true,
      enableReinitialize: true,
      touchOnChange: true
  })
(FallbackTariffSelector)
